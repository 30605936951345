
export default {
  name: 'QuotesWithHeadshots',
  props: {
    slice: { type: Object, required: true },
  },
  computed: {
    refLabel() {
      return 'quote'
    },
    quotes() {
      return this.slice.items.map((item) => {
        return {
          ...item,
          quote: item.quote.replace(/"/g, ''),
          name: item.attribution?.split(', ')[0],
          company: item.attribution?.split(', ')[1] || '',
        }
      })
    },
  },
  methods: {
    linkLabel(item) {
      if (item.read_more_link_label) return item.read_more_link_label
      if (item.name) return `View ${item.name.split(' ')[0]}'s story`
      return 'Read more'
    },
  },
}
