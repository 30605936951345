
export default {
  name: 'Carousel',
  props: {
    items: {
      type: Array,
      required: true,
    },
    refLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
    }
  },
  mounted() {
    if (process.browser) {
      this.initScrollEvent()
    }
  },
  methods: {
    initScrollEvent() {
      const carousel = document.querySelector(`#carousel-${this.refLabel}`)
      carousel.addEventListener('scroll', this.scrollEvent)
    },
    scrollEvent() {
      this.items.forEach((_, index) => {
        const element = document.querySelector(`#${this.refLabel}-${index}`)
        if (this.isInViewport(element)) {
          this.current = index
        }
      })
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.left >= 0 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    nextItem() {
      this.$nextTick(() => {
        const newCurrent =
          this.current === this.items.length - 1
            ? this.items.length - 1
            : this.current + 1

        document
          .getElementById(`${this.refLabel}-${newCurrent}`)
          .scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      })
    },
    prevItem() {
      this.$nextTick(() => {
        const newCurrent = this.current === 0 ? this.current : this.current - 1
        document
          .getElementById(`${this.refLabel}-${newCurrent}`)
          .scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      })
    },
  },
}
